function handleClick(styleExpressionLabel: HTMLLabelElement) {
  const additionalColourSection = document.querySelector<HTMLDivElement>('.proposal-briefing-form__additional-color-section');

  if (additionalColourSection) {
    if (styleExpressionLabel.dataset.showAdditionalColorsSection === 'yes') {
      additionalColourSection.style.display = 'block';
    } else {
      additionalColourSection.style.display = 'none';

      const inputs = additionalColourSection.querySelectorAll<HTMLInputElement>('input');

      if (inputs) {
        inputs.forEach((input) => {
          input.checked = false;
        });
      }
    }
  }
}

export function initializeBriefingStyleExpressionLabels() {
  const styleExpressionLabels = document.querySelectorAll<HTMLLabelElement>('.proposal-briefing-form__style-expression-label');

  styleExpressionLabels.forEach((styleExpressionLabel) => {
    styleExpressionLabel.addEventListener('click', () => {
      handleClick(styleExpressionLabel)
    });

    const input = styleExpressionLabel.previousElementSibling;

    if (input && input.hasAttribute('checked')) {
      styleExpressionLabel.click();
    }
  });
}
