import 'htmx.org';
import Alpine from 'alpinejs';
import { initializeProposalWelcome } from './proposalWelcome';
import { initializeProposalBriefing } from './proposalBriefing';

const initializePage = () => {
  initializeProposalWelcome();
  initializeProposalBriefing();
  Alpine.start();
};

export { initializePage };
