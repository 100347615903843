import ArchilogicEmbed, { PresentationMode } from '@archilogic/embed-api';

async function initializeArchilogicEmbed(element: HTMLDivElement) {
  const archilogicEmbed = new ArchilogicEmbed(element, {
    transparentBackground: true,
    presentationMode: PresentationMode.tourLoop,
    minimap: true,
    showTitle: false,
    showLogo: false,
    lowResTexturesOnly: true,
    bookmarksActive: true,
    uiButtons: {
      birdMode: true,
      personMode: true,
      fullscreen: true,
      bookmarkStrip: true,
      share: false,
      help: true,
      presentation: true,
      exportImage: true,
    },
  });

  async function viewArchilogicScene(
    archilogicId: string | null,
    archilogicTokenElement: HTMLScriptElement
  ) {
    if (!archilogicId) return;
    if (!archilogicTokenElement.textContent) {
      throw "Script element doesn't contain any content";
    }

    const temporaryAccessToken = JSON.parse(archilogicTokenElement.textContent);

    await archilogicEmbed?.loadScene(archilogicId, {
      temporaryAccessToken: temporaryAccessToken,
    });
  }

  (window as any).viewArchilogicScene = viewArchilogicScene;
}

export function initializeArchilogic() {
  const embeds = document.querySelectorAll<HTMLDivElement>(
    '.js-archilogic-embed'
  );

  embeds.forEach((embed) => {
    initializeArchilogicEmbed(embed);
  });
}
