function initializeProposalSubmitForm() {
  const submitButton = document.querySelector<HTMLButtonElement>('.js-proposal-submit-form-button');
  const radioButtons = document.querySelectorAll<HTMLInputElement>('.js-proposal-submit-form-radio');

  if(submitButton && radioButtons) {
    radioButtons.forEach((radio) => {
      radio.addEventListener("click", function() {
        submitButton.removeAttribute("disabled")
        submitButton.innerText = radio.dataset.submitText || "Submit"
      })
    })
  }
}

export function initializeSubmitProposalForm() {
  const proposalSubmitForm = document.querySelector<HTMLFormElement>('.js-proposal-submit-form');

  if(proposalSubmitForm) {
    initializeProposalSubmitForm();
  }
}
