import ArchilogicEmbed, { PresentationMode } from '@archilogic/embed-api';

const classes = {
  archilogicInitialized: 'archilogic-embed--initialized',
  selected: 'selected',
  show: 'show',
};

let archilogicEmbed: ArchilogicEmbed;
let archilogicEmbedContainer: HTMLElement | null;
let archilogicPreviewButton: HTMLAnchorElement | null;
let styleModule: HTMLElement | null;
let styleModuleControls: Array<HTMLElement> = [];
let styleModuleImageGroup: Array<HTMLElement> = [];
let styleModuleData: any = {};

async function initializeArchilogicEmbed() {
  if (styleModule) {
    const styleModuleDataElement = document.getElementById(
      'proposal-style-data'
    );

    if (styleModuleDataElement && styleModuleDataElement.textContent) {
      styleModuleData = JSON.parse(styleModuleDataElement.textContent);

      if (archilogicEmbedContainer && styleModuleData) {
        archilogicEmbed = new ArchilogicEmbed(archilogicEmbedContainer, {
          transparentBackground: true,
          presentationMode: PresentationMode.none,
          minimap: true,
          showTitle: false,
          showLogo: false,
          lowResTexturesOnly: true,
          bookmarksActive: false,
          uiButtons: {
            birdMode: true,
            personMode: true,
            fullscreen: true,
            bookmarkStrip: true,
            share: false,
            help: true,
            presentation: true,
            exportImage: true,
          },
        });
      }
    }
  }
}

async function viewArchilogicScene(control: HTMLElement) {
  if (control && control.dataset.archilogicId && archilogicEmbed) {
    if (
      archilogicEmbedContainer &&
      !archilogicEmbedContainer.classList.contains(
        classes.archilogicInitialized
      )
    ) {
      archilogicEmbedContainer.classList.add(classes.archilogicInitialized);
    }

    await archilogicEmbed.loadScene(control.dataset.archilogicId, {
      temporaryAccessToken: styleModuleData,
    });
  }
}

function updateStyleModuleClasses(control: HTMLElement) {
  styleModuleControls.forEach((control) => {
    control.classList.remove(classes.selected);
  });

  control.classList.add(classes.selected);

  styleModuleImageGroup.forEach((imageContainer) => {
    if (imageContainer.dataset.style === control.dataset.style) {
      imageContainer.classList.add(classes.show);
    } else {
      imageContainer.classList.remove(classes.show);
    }
  });
}

function bindStyleModuleEventListeners() {
  styleModuleControls.forEach((control) => {
    control.addEventListener('click', (e) => {
      e.preventDefault();

      if (!control.classList.contains(classes.selected)) {
        updateStyleModuleClasses(control);
        viewArchilogicScene(control);
      }
    });
  });

  if (archilogicPreviewButton) {
    archilogicPreviewButton.addEventListener('click', (e) => {
      e.preventDefault();

      if (styleModuleControls.length > 0) {
        viewArchilogicScene(styleModuleControls[0]);
      }
    });
  }
}

function getStyleModuleDOMElements() {
  styleModule = document.getElementById('proposal-style');

  if (styleModule) {
    archilogicEmbedContainer = styleModule.querySelector(
      '.proposal-style-archilogic'
    );
    archilogicPreviewButton = styleModule.querySelector(
      '.proposal-style-archilogic-preview-button'
    );
    styleModuleControls = Array.from(
      styleModule.querySelectorAll('.proposal-style-control')
    );
    styleModuleImageGroup = Array.from(
      styleModule.querySelectorAll('.proposal-style-images')
    );
  }
}

function initializeStyleModule() {
  getStyleModuleDOMElements();
  bindStyleModuleEventListeners();
  initializeArchilogicEmbed();
}

export function initializeProposalStyleModule() {
  const proposalStyleContainer = document.querySelector<HTMLFormElement>('.js-proposal-style');

  if(proposalStyleContainer) {
    initializeStyleModule();
  }
}
