function updateFilename(fileInput: HTMLInputElement) {
  const filenameElement = fileInput.parentNode?.querySelector('.proposal-briefing-form__field-filename');

  if (filenameElement) {
    filenameElement.innerHTML = fileInput.value.split('\\').pop() || '';
  }
}

function handleFileInputChange(fileInput: HTMLInputElement) {
  fileInput.addEventListener('change', () => {
    updateFilename(fileInput);
  });
}

function createFileNameElement(fileInput: HTMLInputElement) {
  const label = fileInput.parentNode?.querySelector('.proposal-briefing-form__field-label');

  if (label) {
    const filenameElement: HTMLSpanElement = document.createElement('span');
    filenameElement.classList.add('proposal-briefing-form__field-filename');
    label.parentNode?.insertBefore(filenameElement, label.nextSibling);

    updateFilename(fileInput);
  }
}

function initializeFileInput(fileInput: HTMLInputElement) {
  createFileNameElement(fileInput);
  handleFileInputChange(fileInput);
}

export function initializeFileInputs() {
  const fileInputs = document.querySelectorAll<HTMLInputElement>('input[type="file"]');

  fileInputs.forEach((fileInput) => {
    initializeFileInput(fileInput);
  });
}
