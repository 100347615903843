function initializeSubscriptionDetails() {
  const container = document.querySelector<HTMLDivElement>(
    '.subscription-details'
  );
  if (!container) return;
  const link = container.querySelector<HTMLDivElement>('.js-subscription-details-button');
  const header = container.querySelector<HTMLDivElement>('.js-subscription-details-header');
  if (!(link && header)) return;
  header.addEventListener('click', function (e) {
    container.classList.toggle('subscription-details--open');
    link.setAttribute(
      'aria-expanded',
      container.classList.contains('subscription-details--open') ? 'true' : 'false'
    );
  });
}

function handleProposalWelcomePage(page: HTMLBodyElement) {
  initializeSubscriptionDetails();
}

export function initializeProposalWelcome() {
  const page = document.querySelector(
    'body.page-proposal-welcome'
  ) as HTMLBodyElement | null;
  if (page) {
    handleProposalWelcomePage(page);
  }
}
