import Alpine from 'alpinejs';

function handleProposalBriefingUnsavedChanges() {
  const confirmationMessage =
    'You have unsaved changes. If you navigate away from this page without saving them they will be lost. Are you sure?';

  document.addEventListener('alpine:init', function () {
    Alpine.store('briefingFormChanges', false);
  });

  // Handle if user closes/refreshes tab
  window.addEventListener('beforeunload', function (event) {
    const unsavedChanges = Alpine.store('briefingFormChanges');

    if (unsavedChanges) {
      (event || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  });
}

export function initializeProposalBriefing() {
  const page = document.querySelector<HTMLBodyElement>(
    'body.page-proposal-briefing'
  );
  if (page) {
    handleProposalBriefingUnsavedChanges();
  }
}
