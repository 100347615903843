import { initializeArchilogic } from './archilogic';
import { initializeBriefingStyleExpressionLabels } from './briefing_style_expression_labels';
import { initializeFileInputs } from './file_input';
import { initializeImageGalleries } from './image_gallery';
import { initializeProposalStyleModule } from './styles_module';
import { initializeSubmitProposalForm } from './submit_proposal_form';

const initializeComponents = () => {
  initializeArchilogic();
  initializeBriefingStyleExpressionLabels();
  initializeFileInputs();
  initializeImageGalleries();
  initializeProposalStyleModule();
  initializeSubmitProposalForm();
};

export { initializeComponents };
